import React from "react";
import styled from "styled-components";

import { UI_COLORS } from "../../../../config/consts";

const StyledSectionWrapper = styled.div`
  position: relative;
  /* width: 100vw; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* flex-wrap: nowrap; */
  gap: 6rem;
  /* overflow: hidden; */
  /* padding: 12rem 4rem; */
  padding: 0 6rem;

  @media (max-width: 768px) {
    padding: 22rem 3rem;
    min-height: 80vh;
    min-height: auto;
    justify-content: flex-start;
  }

  @media (max-width: 500px) {
    gap: 12rem;
    padding: 18rem 3rem;
  }
`;

const SectionWrapperTitle = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-size: 7.5rem;
  font-family: "dokdo", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: ${UI_COLORS.cream};
  text-shadow: 0 0.8rem ${UI_COLORS.black};

  @media (max-width: 768px) {
    font-size: 9.5rem;
  }
`;

export default function SectionWrapper(props) {
  const { title, children, id } = props;

  return (
    <StyledSectionWrapper id={id}>
      <SectionWrapperTitle>{title}</SectionWrapperTitle>
      {children}
    </StyledSectionWrapper>
  );
}
