import React from "react";
import styled from "styled-components";

import BuyButton from "./BuyButton";

import { UI_COLORS } from "../../../../../config/consts";

const BuySectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6rem;
  width: 100%;
`;

export default function BuySection() {
  return (
    <BuySectionWrapper>
      {process.env.REACT_APP_IS_JUPITER_ENABLED && (
        <BuyButton
          title="BUY ON JUPITER"
          color={UI_COLORS.green}
          goTo="https://jup.ag/swap/SOL-4MwJtSuzKhmfTXdNtendHsrNKX2555iXwigwTK8SVyjQ"
        />
      )}
      <BuyButton
        title="BUY ON RAYDIUM"
        color={UI_COLORS.purple2}
        goTo="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4MwJtSuzKhmfTXdNtendHsrNKX2555iXwigwTK8SVyjQ&fixed=in"
      />
    </BuySectionWrapper>
  );
}
