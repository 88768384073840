import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
} from "date-fns";

const formatTimeAgo = (tradeTimestamp) => {
  const now = new Date();
  const tradeTime = new Date(tradeTimestamp);

  const diffHours = differenceInHours(now, tradeTime);
  const diffMinutes = differenceInMinutes(now, tradeTime);
  const diffSeconds = differenceInSeconds(now, tradeTime);

  if (diffHours >= 1) {
    const remainingMinutes = diffMinutes - diffHours * 60;
    return `${diffHours} hour(s) and ${remainingMinutes} minute(s) ago`;
  } else if (diffMinutes >= 1) {
    return `${diffMinutes} minute(s) ago`;
  } else {
    return `${diffSeconds} second(s) ago`;
  }
};

export default formatTimeAgo;
