import React from "react";
import styled from "styled-components";
import TrumpImg from "../../../../../assets/images/tokenomic-trump.png";
import RodmanImg from "../../../../../assets/images/tokenomic-rodman.png";
// import KimImg from "../../../../../assets/images/tokenomic-kim.png";
import KimImg from "../../../../../assets/images/kimjonungold.png";
import PutinImg from "../../../../../assets/images/tokenomic-putin.png";

import Card from "./Card";

const TokenomicsCardsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* row-gap: 6rem; */
  gap: 4rem;

  @media (max-width: 500px) {
    gap: 2.5rem;
  }
`;

export default function TokenomicsCards() {
  return (
    <TokenomicsCardsWrapper>
      <Card img={TrumpImg} title="TAXES" value="0"></Card>
      <Card img={RodmanImg} title="LIQUIDITY" value="BURNED"></Card>
      <Card img={KimImg} title="SUPPLY" value="1000000000"></Card>
      <Card img={PutinImg} title="MINT & FREEZE" value="REVOKED"></Card>
    </TokenomicsCardsWrapper>
  );
}
