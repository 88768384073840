import React from "react";
import styled from "styled-components";

import { UI_COLORS, CONTRACT_ADDRESS } from "../../../../../config/consts";

const StyledCopyButton = styled.button`
  background: ${UI_COLORS.red};
  min-width: 32rem; /* Ensures the button has a minimum width */
  height: 6rem; /* Fixed height to maintain vertical size */

  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  color: black;
  border: 3px solid ${UI_COLORS.black};
  border-radius: 18px;
  font-family: "dokdo", sans-serif;
  font-weight: 400;
  color: ${UI_COLORS.white};
  font-size: 3rem;
  text-align: center;
  text-shadow: 0 0.2rem ${UI_COLORS.black};
  box-shadow: 0 8px ${UI_COLORS.black};

  @media (max-width: 500px) {
      font-size: 3.6rem;
      min-width: 36rem;
      border: 2px solid ${UI_COLORS.black};
      box-shadow: 0 4px ${UI_COLORS.black};
    }
  
  &:hover {
    background: ${UI_COLORS.blue};
  }
  
  &:active {
    transform: translateY(4px); /* Moves the button down */
    box-shadow: 0 4px ${UI_COLORS.black}; /* Adjusts the shadow to simulate depth change */
  }
  
`;

export default function CopyButton() {
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => setCopied(true),
      () => setCopied(false)
    );
  };

  return (
    <StyledCopyButton onClick={() => copyToClipboard(CONTRACT_ADDRESS)}>
      {copied ? "Copied!" : "Copy to clipboard"}
    </StyledCopyButton>
  );
}
