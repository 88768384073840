import * as THREE from "three";

import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

import Application from "../../../Application";
import EventEmitter from "../../Utils/EventEmitter";

export default class Test extends EventEmitter {
  constructor() {
    super();

    this.application = new Application();
    this.scene = this.application.scene;
    this.resources = this.application.resources;

    this.model = null; // Placeholder for the loaded model

    this.amplitude = 1.6;
    this.frequency = 15; // How fast it moves up and down
    this.distance = 1; // Base distance from the camera

    // Properties for randomness
    this.randomAmplitudeFactor = 0.06; // Max random factor to multiply amplitude
    this.randomFrequencyFactor = 0.01; // Max random factor to multiply frequency
    this.phaseShift = 0; // Initial phase shift
    this.phaseShiftSpeed = 0.02; // How quickly the phase shifts

    this.mixer = null;

    this.loadModel();
  }

  loadModel() {
    const loader = new GLTFLoader();

    loader.load(
      "models/test.glb",
      (gltf) => {
        this.model = gltf;

        const SCALE_FACTOR = 34;
        this.model.scene.scale.set(SCALE_FACTOR, SCALE_FACTOR, SCALE_FACTOR); // Scale the whole model
        this.model.scene.rotateY(Math.PI / 2);
        this.model.scene.position.y += 75;

        // const mixer = new THREE.AnimationMixer(this.model);

        // let action;
        // this.model.animations.forEach((clip) => {
        //   if (clip.name === "celebrating") {

        //     action = mixer.clipAction(clip);
        //     action.play();
        //   }
        // });

        // console.log("this.model");
        // console.log(this.model);

        this.mixer = new THREE.AnimationMixer(this.model.scene); // Initialize mixer with gltf.scene

        console.log("this.mixer ");
        console.log(this.mixer);

        gltf.animations.forEach((clip) => {
          if (clip.name === "Idle") {
            const action = this.mixer.clipAction(clip);
            action.setEffectiveTimeScale(0.1); 
            action.play();
          }
        });

        this.model.scene.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.castShadow = true;
          }
        });

        this.setModel(); // Call setModel once the model is loaded
      },
      undefined,
      (error) => {
        console.error("An error happened while loading the model:", error);
      }
    );
  }

  setModel() {
    // Check if the model is loaded before trying to add it to the scene
    if (this.model) {
      this.scene.add(this.model.scene);
    } else {
      console.error("Model is not loaded yet");
    }
  }

  update() {
    // Ensure the model is loaded
    if (!this.model) return;

    const elapsedTime = this.application.time.elapsed;

    // Use a time factor that grows initially but converges on a constant value
    // This can help make the movement start smoothly but reach and maintain a consistent speed over time
    const timeFactor = 1 - Math.exp(-elapsedTime * 0.0001);

    const randomAmplitude =
      this.amplitude * (1 + Math.random() * this.randomAmplitudeFactor);
    const randomFrequency =
      this.frequency * (1 + Math.random() * this.randomFrequencyFactor);

    this.phaseShift += this.phaseShiftSpeed;

    // Apply the timeFactor to the sine function to maintain consistent oscillation speed
    this.model.scene.position.y =
      75 +
      Math.sin(timeFactor * randomFrequency + this.phaseShift) *
        randomAmplitude;
    this.model.scene.position.z =
      this.distance +
      Math.sin(timeFactor * randomFrequency + this.phaseShift) *
        randomAmplitude;
    this.model.scene.position.x =
      Math.sin(timeFactor * randomFrequency + this.phaseShift) *
      randomAmplitude;

    const delta = this.application.time.delta /100; // Assuming there's a clock in your Application instance
    this.mixer.update(delta);
  }
}
