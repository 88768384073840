import React from "react";
import styled from "styled-components";
import { UI_COLORS, CONTRACT_ADDRESS } from "../../../../../config/consts";

const StyledWrapper = styled.div``;

const StyledAddress = styled.div`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  display: flex;
  flex-direction: column;

  p {
    font-family: "dokdo", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: ${UI_COLORS.black};
    line-height: 1;
  }

  .address-title {
    font-size: 5.2rem;
    text-align: left;
    color: ${UI_COLORS.white};
    text-shadow: 0 0.3rem ${UI_COLORS.black};

    @media (max-width: 500px) {
      font-size: 6.2rem;
    }
  }

  .address-value {
    font-size: 2.2rem;
    text-align: left;
    color: ${UI_COLORS.white};
    text-shadow: 0 0.2rem ${UI_COLORS.black};
    opacity: 0.94;

    @media (max-width: 500px) {
      font-size: 2.6rem;
    }
  }
`;

export default function Address() {
  return (
    <StyledWrapper>
      <StyledAddress>
        <p className="address-title">Contract Address</p>
        <p className="address-value"> {CONTRACT_ADDRESS}</p>
      </StyledAddress>
    </StyledWrapper>
  );
}
