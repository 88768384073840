import { DEXSCREENER_TOKEN_DATA_ENDPOINT } from "../../config/consts";

const getTokenData = async () => {
  try {
    const response = await fetch(DEXSCREENER_TOKEN_DATA_ENDPOINT);

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Failed to fetch token data:", error.message);

    return null;
  }
};

export default getTokenData;
