import React from "react";
import styled from "styled-components";

import { UI_COLORS } from "../../../../config/consts";

import LogoImg from "../../../../assets/logos/logo.png";

const StyledLogo = styled.a`
  position: relative;
  /* z-index: 2; */
  display: flex;
  pointer-events: all;

  text-decoration: none;
  color: inherit;
  outline: none;
  text-shadow: 0 0.6rem ${UI_COLORS.black};

  h3 {
    color: ${UI_COLORS.cream};
    font-family: "dokdo", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: ${(props) => (props.size == "small" ? "5rem" : "7rem")};
    word-break: break-all;
    line-height: 0.7;
    transform: rotate(3deg);
  }

  .logo-img-wrapper {
  }

  img {
    position: absolute;
    right: 1rem;
    bottom: 0;
    width: 8rem;
    width: ${(props) => (props.size == "small" ? "5rem" : "8rem")};
    height: auto;
    transform: rotate(-3deg);
  }
`;

export default function Logo({ size }) {
  return (
    <StyledLogo href="#webgl" size={size}>
      <h3>
        Rocket
        <br />
        Man
      </h3>

      <img src={LogoImg} />
    </StyledLogo>
  );
}
