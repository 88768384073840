import EventEmitter from "./EventEmitter";

export default class Mouse extends EventEmitter {
  constructor() {
    super();

    // Setup
    this.x = 0;
    this.y = 0;

    // Resize event
    // this.on("mousemove", (event) => {
    //   console.log("MOUSE MOVE")
    //   if (event.clientX && event.clientY) {
    //     this.x = event.clientX;
    //     this.y = event.clientY;
    //   }
    // });

    window.addEventListener("mousemove", (event) => {
      if (event.clientX && event.clientY) {
        this.x = event.clientX;
        this.y = event.clientY;


      }
    });
  }
}
