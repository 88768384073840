import React from "react";
import styled from "styled-components";

import Logo from "../Logo";
import Menu from "./Menu";
import PushButton from "./PushButton";

const HeaderWrapper = styled.div`
  z-index: 999999;
  width: 100%;
  padding: 4rem calc(4.16667vw * 1) 0;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  /* pointer-events: none; */
  position: absolute;
  left: 0;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  font-family: "Tusker 5500", sans-serif;
  font-size: 1.5rem;
  line-height: 1;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 6rem 4rem;
  }
`;

export default function Header() {
  return (
    <HeaderWrapper>
      <Logo />
      <Menu />
      <PushButton />
    </HeaderWrapper>
  );
}
