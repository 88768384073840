import { Connection } from "@solana/web3.js";

const getConnection = () => {
return new Connection(process.env.REACT_APP_RPC_HTTPS_ENDPOINT, {
  wsEndpoint: process.env.REACT_APP_RPC_WEBSOCKET_ENDPOINT,
});

  // const RPC_HTTPS_ENDPOINT = "https://api.devnet.solana.com";

  // return new Connection(RPC_HTTPS_ENDPOINT);
};

export default getConnection;
