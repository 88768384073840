import * as THREE from "three";
import * as BufferGeometryUtils from "three/addons/utils/BufferGeometryUtils.js";

import { colors } from "../../../config/consts";

import EventEmitter from "../../Utils/EventEmitter";

export default class Sea extends EventEmitter {
  constructor() {
    super();

    var geom = new THREE.CylinderGeometry(600, 600, 800, 40, 10);
    geom.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2));
    //  geom.mergeVertices();
    var l = geom.vertices?.length;

    this.waves = [];

    for (var i = 0; i < l; i++) {
      var v = geom.vertices[i];
      this.waves.push({
        y: v.y,
        x: v.x,
        z: v.z,
        ang: Math.random() * Math.PI * 2,
        amp: 5 + Math.random() * 15,
        speed: 0.016 + Math.random() * 0.032 * 0.1,
      });
    }
    var mat = new THREE.MeshPhongMaterial({
      color: colors.blue,
      transparent: true,
      opacity: 0.8,
      flatShading: true,
      // shading: THREE.FlatShading,
    });

    this.mesh = new THREE.Mesh(geom, mat);
    this.mesh.receiveShadow = true;

    this.mesh.position.y = -600;
  }

  moveWaves() {}
}
