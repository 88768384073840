import { PublicKey } from "@solana/web3.js";

import formatTimeAgo from "../formatTimeAgo";

import getConnection from "./getConnection";

import parseBulkTransactions from "./parseBulkTransactions";

const solanaConnection = getConnection();

const processedSignatures = new Set();

const getTransactions = async (tokenAddress, numTx) => {
  try {
    const pubKey = new PublicKey(tokenAddress);

    let transactionList = await solanaConnection.getSignaturesForAddress(
      pubKey,
      {
        limit: numTx,
      }
    );

    const filteredTransactionList = transactionList.filter((tx) => {
      return !tx.err;
    });

    let signatureList = filteredTransactionList.reduce((acc, transaction) => {
      if (!processedSignatures.has(transaction.signature)) {
        acc.push(transaction.signature);
      }
      return acc;
    }, []);

    if (signatureList.length == 0) {
      return [];
    } else {
      const parsedTransactions = await parseBulkTransactions(signatureList);

      signatureList.forEach((signature) => processedSignatures.add(signature));

      return parsedTransactions;
    }
  } catch (error) {
    console.log("error from getTransactions");
    console.log(error);
  }
};

export default getTransactions;
