import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
// import eventBus from "./EventBus";
import { UI_ID_NAME } from "../../config/consts";
import AboutSection from "../UI/sections/About";
import TokenomicsSection from "../UI/sections/Tokenomics";
import CommunitySection from "../UI/sections/Community";

import getTransactions from "../../utils/blockchain/getTransactions";
import getTokenData from "../../utils/blockchain/getTokenData";

const UIWrapper = styled.div``;

const UI = () => {
  React.useEffect(() => {
    if (!localStorage.getItem("sessionStartTimestamp")) {
      const now = new Date().getTime();
      localStorage.setItem("sessionStartTimestamp", now.toString());
    }
  }, []);

  const [tokendata, setTokenData] = React.useState(null);
  const [tokenPrice, setTokenPrice] = React.useState(null);

  React.useEffect(() => {
    console.log("tokenPrice");
    console.log(tokenPrice);
  }, [tokenPrice]);

  const numTx = 5;

  const interval = 5000;

  React.useEffect(() => {
    const fetchTransactions = async () => {
      await getTransactions(process.env.REACT_APP_TOKEN_ADDRESS, numTx);
    };

    const fetchTokendata = async () => {
      const data = await getTokenData();

      if (data?.pair?.priceUsd) {
        setTokenPrice(data?.pair?.priceUsd);
      }

      setTokenData(data);
    };

    fetchTransactions();
    fetchTokendata();
    const intervalId = setInterval(fetchTransactions, interval);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <UIWrapper className="UI">
      <Header></Header>
      <AboutSection id="about" />
      <TokenomicsSection tokenPrice={tokenPrice}/>
      {/* <BuySection /> */}
      <CommunitySection />
      <Footer />
    </UIWrapper>
  );
};

const createUI = () => {
  ReactDOM.render(<UI />, document.getElementById(UI_ID_NAME));
};

export default class {
  constructor() {
    createUI();
  }
}
