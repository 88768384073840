import * as THREE from "three";

import Application from "../index.js";

export default class Lights {
  constructor() {
    this.application = new Application();
    this.scene = this.application.scene;
    this.debug = this.application.debug;
    this.setLights();
  }

  setLights() {
    const ambientLight = new THREE.AmbientLight(0xdc8874, 0.5);
    const hemisphereLight = new THREE.HemisphereLight(0xaaaaaa, 0x000000, 0.);
    const shadowLight = new THREE.DirectionalLight(0xffffff, 0.9);

    shadowLight.position.set(150, 350, 350);
    shadowLight.castShadow = true;
    shadowLight.shadow.camera.left = -400;
    shadowLight.shadow.camera.right = 400;
    shadowLight.shadow.camera.top = 400;
    shadowLight.shadow.camera.bottom = -400;
    shadowLight.shadow.camera.near = 1;
    shadowLight.shadow.camera.far = 1000;
    shadowLight.shadow.mapSize.width = 2048;
    shadowLight.shadow.mapSize.height = 2048;

    this.scene.add(ambientLight);
    this.scene.add(hemisphereLight);
    this.scene.add(shadowLight);
  }

  update() {}
}
