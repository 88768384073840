import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { UI_ID_NAME, WEBGL_WRAPPER_ID_NAME } from "./config/consts";
import "./index.css";

import Application from "./Application";
import getTransactions from "./utils/blockchain/getTransactions";
import getTokenData from "./utils/blockchain/getTokenData";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const root = ReactDOM.createRoot(document.getElementById("root"));

const Root = () => {
  const applicationRef = React.useRef(null);

  React.useEffect(() => {
    if (!applicationRef.current) {
      applicationRef.current = new Application();
    }
  }, []);

  // const [tokendata, setTokenData] = React.useState(null);
  // const [tokenPrice, setTokenPrice] = React.useState(null);

  // React.useEffect(() => {
  //   console.log("tokenPrice");
  //   console.log(tokenPrice);
  // }, [tokenPrice]);

  // const numTx = 5;

  // const interval = 5000;

  // React.useEffect(() => {
  //   const fetchTransactions = async () => {
  //     await getTransactions(process.env.REACT_APP_TOKEN_ADDRESS, numTx);
  //   };

  //   const fetchTokendata = async () => {
  //     const data = await getTokenData();

  //     if (data?.pair?.priceUsd) {
  //       setTokenPrice(data?.pair?.priceUsd);
  //     }

  //     setTokenData(data);
  //   };

  //   fetchTransactions();
  //   fetchTokendata();
  //   const intervalId = setInterval(fetchTransactions, interval);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <>
      {/* <div id="css"></div> */}
      <div id="webgl"></div>
      <div id={UI_ID_NAME}></div>
    </>
  );
};

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
