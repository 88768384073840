import Application from "../";
import * as THREE from "three";

import Airplane from "./Airplane";
import Cloud from "./Cloud";
import Pilot from "./Pilot";
import Sea from "./Sea";
import Sky from "./Sky";
import Test from "./Test";

export default class World {
  constructor() {
    this.application = new Application();
    this.scene = this.application.scene;
    // this.resources = this.application.resources;

    this.airplane = new Airplane();
    this.cloud = new Cloud();
    this.pilot = new Pilot();
    this.sea = new Sea();
    this.sky = new Sky();
    this.test = new Test();

    console.log("this.test");
    console.log(this.test);

    // this.scene.add(this.airplane.mesh);
    this.scene.add(this.cloud.mesh);
    this.scene.add(this.sea.mesh);
    this.scene.add(this.sky.mesh);

    // Wait for resources
    // this.resources.on("ready", () => {
    //   console.log("world ready");
    // });
  }

  update() {
    // const xMousePosition = this.application.mouse.x;
    const yMousePosition = this.application.mouse.y;
    const screenHeight = this.application.sizes.height;

    this.airplane.update(yMousePosition, screenHeight);

    this.sky.update();
    this.sea.moveWaves();
     this.test.update()
  }
}
