import React from "react";
import styled from "styled-components";

import SectionWrapper from "../../components/SectionWrapper";

import MoonButton from "../../components/MoonButton";

import TwitterImg from "../../../../assets/icons/twitter.png";
import TelegramImg from "../../../../assets/icons/telegram.png";

const CommunitySectionWrapper = styled.section`
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  /* padding:4rem; */
  position: relative;
`;

const CommunityIconImg = styled.img`
  opacity: 0.86;
  width: 50%;

  @media (max-width: 500px) {
    width: 45%;
  }
`;

export default function CommunitySection() {
  return (
    <SectionWrapper id="community" title="community">
      <CommunitySectionWrapper>
        <MoonButton goTo="https://twitter.com/rocketman_coin">
          <CommunityIconImg src={TwitterImg}></CommunityIconImg>
        </MoonButton>
        <MoonButton goTo="https://t.me/rocketmanchannel">
          <CommunityIconImg src={TelegramImg}></CommunityIconImg>
        </MoonButton>
      </CommunitySectionWrapper>
    </SectionWrapper>
  );
}
