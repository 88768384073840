import React from "react";
import styled from "styled-components";

import { UI_COLORS } from "../../../../config/consts";

import Logo from "../../components/Logo";
import MoonButton from "../../components/MoonButton";

import FooterImg from "../../../../assets/images/footer-img.png";

import DexscreenerImg from "../../../../assets/icons/dexscreener.png";
import JupiterImg from "../../../../assets/icons/jupiter.png";
import TwitterImg from "../../../../assets/icons/twitter.png";
import TelegramImg from "../../../../assets/icons/telegram.png";

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 32rem;
  /* padding: 4rem; */
  border-radius: 5rem 5rem 0 0;
  background-color: #1d2d32;
  position: relative;
`;

const LogoWrapper = styled.div`
  position: relative;
`;

const FooterLeftWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5rem;

  .footer-icons {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 5rem;
    transform: translate(1.2rem, 0);

    @media (max-width: 500px) {
      gap: 6rem;
      transform: translate(2rem, 0);
    }

    img {
      opacity: 1;
      width: 92%;

      @media (max-width: 500px) {
        width: 125%;
      }
    }
  }
  .footer-bottom-text {
    color: ${UI_COLORS.cream};
    font-family: "dokdo", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.4rem;
    opacity: 0.9;

    @media (max-width: 500px) {
      font-size: 1.8rem;
      line-height:1;
      }

  }
`;

const FooterRightWrapper = styled.div`
  flex: 1;
  position: relative;

  .text-lfg {
    color: ${UI_COLORS.red};
    font-family: "dokdo", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -40%) rotate(-5deg);
    text-shadow: 0px 5px 0px ${UI_COLORS.black};




  }

  img {
    width: 70%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <FooterLeftWrapper>
        <LogoWrapper>
          <Logo size="small"></Logo>
        </LogoWrapper>

        <div className="footer-icons">
          <MoonButton size="2rem" goTo="https://dexscreener.com/solana/6nuv7unm8nmagw7pufm49u47o816fj8qvauokyfmt4sv">
            <img src={DexscreenerImg}></img>
          </MoonButton>
          {/* <MoonButton size="2rem" goTo="https://twitter.com/">
            <img src={JupiterImg}></img>
          </MoonButton> */}
          <MoonButton size="2rem" goTo="https://t.me/rocketmanchannel">
            <img src={TelegramImg}></img>
          </MoonButton>
          <MoonButton size="2rem" goTo="https://twitter.com/rocketman_coin<">
            <img src={TwitterImg}></img>
          </MoonButton>
        </div>

        <p className="footer-bottom-text">
          $ROCKETMAN is simply a meme coin with no intrinsic value or
          expectation of financial return. © 2024 by $ROCKETMAN. All rights
          reserved.
        </p>
      </FooterLeftWrapper>

      <FooterRightWrapper>
        <p className="text-lfg">LFG!</p>
        <img src={FooterImg}></img>
      </FooterRightWrapper>
    </FooterWrapper>
  );
}
