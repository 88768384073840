import * as THREE from "three";
import Application from "../index";
import EventEmitter from "../Utils/EventEmitter";

import normalize from "../../utils/normalize";

export default class Camera extends EventEmitter {
  constructor() {
    super();
    this.application = new Application();
    this.sizes = this.application.sizes;
    this.scene = this.application.scene;
    this.renderer = this.application.renderer;
    this.resources = this.application.resources;
    this.time = this.application.time;
    this.debug = this.application.debug;

    // this.position = new THREE.Vector3(0, 0, 0);
    // this.focalPoint = new THREE.Vector3(0, 0, 0);

    this.setInstance();
  }

  setInstance() {
    const fieldOfView = 60;
    const nearPlane = 1; //change to 1?
    const farPlane = 10000;

    this.instance = new THREE.PerspectiveCamera(
      fieldOfView,
      this.sizes.width / this.sizes.height,
      nearPlane,
      farPlane
    );

    this.instance.position.x = 0;
    this.instance.position.z = 200;
    this.instance.position.y = 100;

    // this.scene.add(this.instance);
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    // this.instance.fov = normalize(this.application.mouse.x, -1, 1, 40, 80);
    // this.instance.updateProjectionMatrix();
  }
}
