import React from "react";
import styled from "styled-components";

import kimJonUnImg from "../../../../assets/images/kimjonun-rocket.png";

import { UI_COLORS } from "../../../../config/consts";

const AboutCardWrapper = styled.div`
  align-content: center;
  align-items: center;
  background-color: ${UI_COLORS.white};
  border-radius: 18px;
  box-shadow: 0 8px ${UI_COLORS.black};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  border: 5px solid ${UI_COLORS.blue};
  max-width: 75%;
  transform: rotate(2deg);
  /* transform-origin: bottom left; */

  @media (max-width: 768px) {
    padding: 1rem;
    max-width: 95%;
    /* min-height: 70vh;
    justify-content: flex-start; */
  }
`;

const AboutCardContentWrapper = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 6rem;
  justify-content: center;
  overflow: hidden;
  padding: 50px 60px 50px 40px;
  position: relative;

  @media (max-width: 768px) {
    padding: 3rem;
    gap: 3rem;
  }
`;

const AboutCardContentImageWrapper = styled.div`
  background-color: ${UI_COLORS.red};
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  justify-content: center;
  overflow: visible;
  padding: 0;
  position: relative;
  flex: 1;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-position: center;
    object-fit: cover;
    image-rendering: auto;
    transform: scale(66%);
  }
`;

const AboutCardTextWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 6rem;
  height: min-content;
  justify-content: flex-start;
  overflow: visible;
  padding: 0;
  position: relative;
  flex: 1;

  @media (max-width: 768px) {
    gap: 4rem;
  }

  @media (max-width: 500px) {
    gap: 3rem;
  }

  .inner-text-title-wrapper {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    transform: none;

    p {
      font-family: "dokdo", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: ${UI_COLORS.black};
      font-size: 6.2rem;
      line-height: 0.6;
      opacity: 0.95;

      @media (max-width: 768px) {
        font-size: 7.2rem;
      }
    }
  }

  .inner-text-description-wrapper {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    transform: none;
    p {
      font-family: "dokdo", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: ${UI_COLORS.black};
      font-size: 3rem;
      line-height: 1;
      opacity: 0.9;

      @media (max-width: 500px) {
        font-size: 3.8rem;
      }
    }
  }
`;

const AboutCardSeparatorWrapper = styled.div`
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: center;
  overflow: visible;
  padding: 0 10px;
  position: relative;
  width: min-content;

  .separator {
    background-color: #d2d0c6;
    flex: none;
    height: 276px;
    position: relative;
    width: 2px;
  }
`;

export default function index() {
  return (
    <AboutCardWrapper>
      <AboutCardContentWrapper>
        <AboutCardContentImageWrapper>
          <img src={kimJonUnImg}></img>
        </AboutCardContentImageWrapper>

        <AboutCardSeparatorWrapper>
          <div className="separator"></div>
        </AboutCardSeparatorWrapper>

        <AboutCardTextWrapper>
          <div className="inner-text-title-wrapper">
            <p>Rocket Man</p>
          </div>

          <div className="inner-text-description-wrapper">
            <p>
              KIM JONG UN'S COSMIC MEME RIDE. IT'S NOT JUST A TOKEN; IT'S A
              MOONSHOT WITH MEME MAGIC. FOR CRYPTO DEGENS WHO MEME THEIR WAY TO
              THE MOON, THIS TOKEN UNITES US IN A QUEST FOR GALACTIC GAINS.
            </p>
          </div>
        </AboutCardTextWrapper>
      </AboutCardContentWrapper>
    </AboutCardWrapper>
  );
}
