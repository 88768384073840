import formatTimeAgo from "../formatTimeAgo";

const parseBulkTransactions = async (transactions) => {
  try {
    const headers = new Headers();
    headers.append("x-api-key", process.env.REACT_APP_SHYFT_API_KEY);
    headers.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      network: "mainnet-beta",
      transaction_signatures: transactions,
      enable_raw: false,
      enable_events: true,
    });

    var requestOptions = {
      method: "POST",
      headers,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://api.shyft.to/sol/v1/transaction/parse_selected",
      requestOptions
    );

    const responseJson = await response.json();

    const parsedTransactions = responseJson.result;

    const buys = parsedTransactions
      .filter((tx) => {
        // return (
        //   tx?.actions?.[0]?.info?.swaps?.[0]?.in?.token_address ==
        //   "So11111111111111111111111111111111111111112"
        // );

        return (
          tx?.actions?.[0]?.info?.tokens_swapped?.in?.token_address ==
          "So11111111111111111111111111111111111111112"
        );
      })
      .map((tx) => {
        const txTs = Date.parse(tx.timestamp);
        const timeAgo = formatTimeAgo(txTs);

        return { ...tx, timeAgo };
      });

    return buys;
  } catch (error) {
    console.log("error from parseBulkTransactions");
    console.log(error);
  }
};

export default parseBulkTransactions;
