import * as THREE from "three";

import Cloud from "../Cloud";
import EventEmitter from "../../Utils/EventEmitter";

export default class Sky extends EventEmitter {
  constructor() {
    super();

    this.mesh = new THREE.Object3D();
    this.nClouds = 20;
    this.clouds = [];
    var stepAngle = (Math.PI * 2) / this.nClouds;
    for (var i = 0; i < this.nClouds; i++) {
      var c = new Cloud();
      this.clouds.push(c);
      var a = stepAngle * i;
      var h = 750 + Math.random() * 200;
      c.mesh.position.y = Math.sin(a) * h;
      c.mesh.position.x = Math.cos(a) * h;
      c.mesh.position.z = -400 - Math.random() * 400;
      c.mesh.rotation.z = a + Math.PI / 2;
      var s = 1 + Math.random() * 2;
      c.mesh.scale.set(s, s, s);
      this.mesh.add(c.mesh);

      this.mesh.position.y = -600;
    }
  }

  update(){
    this.mesh.rotation.z += .0005;
  }
}
