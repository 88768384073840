import React from "react";
import styled from "styled-components";

import Logo from "../../../../../assets/images/logo.png";
import { UI_COLORS } from "../../../../../config/consts";

const StyledAvatar = styled.img`
  width: ${(props) => props?.size || "75px"};
  height: ${(props) => props?.size || "75px"};
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  background-color: ${UI_COLORS.purple};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border: 3px solid ${UI_COLORS.black};

  @media (max-width: 500px) {
    border: 2px solid ${UI_COLORS.black};
    width: ${(props) => props?.size || "50px"};
    height: ${(props) => props?.size || "50px"};
  }
`;

const Avatar = ({ src, size }) => {
  return <StyledAvatar src={Logo} size={size} alt="Avatar" />;
};

export default Avatar;
