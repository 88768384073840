import React from "react";
import styled from "styled-components";
import { UI_COLORS, CONTRACT_ADDRESS } from "../../../../../config/consts";

import Avatar from "./Avatar";
import Address from "./Address";
import CopyButton from "./CopyButton";

const CardWrapper = styled.div`
  border-radius: 20px;
  padding: 2rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${UI_COLORS.white};
  box-shadow: 0 8px ${UI_COLORS.black};
  width: 100%;
  border: 3px solid ${UI_COLORS.black};
  background-color:  ${UI_COLORS.red};

  @media (max-width: 500px) {
    border: 2px solid ${UI_COLORS.black};
    }

`;

const ContractCard = () => {
  return (
    <CardWrapper>
      <Avatar />
      <Address />
      <CopyButton />
    </CardWrapper>
  );
};

export default ContractCard;
