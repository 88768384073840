import * as THREE from "three";

import Pilot from "../Pilot";

import { colors } from "../../../config/consts";

import normalize from "../../../utils/normalize";

import EventEmitter from "../../Utils/EventEmitter";

export default class Airplane extends EventEmitter {
  constructor(xMousePosition, yMousePosition) {
    super();

    this.xMousePosition = xMousePosition;
    this.yMousePosition = yMousePosition;

    this.mesh = new THREE.Object3D();
    this.mesh.name = "airPlane";

    // Cockpit

    var geomCockpit = new THREE.BoxGeometry(80, 50, 50, 1, 1, 1);
    var matCockpit = new THREE.MeshPhongMaterial({
      color: colors.red,
      flatShading: true,
      // shading: THREE.FlatShading,
    });

    // geomCockpit.vertices[4].y -= 10;
    // geomCockpit.vertices[4].z += 20;
    // geomCockpit.vertices[5].y -= 10;
    // geomCockpit.vertices[5].z -= 20;
    // geomCockpit.vertices[6].y += 30;
    // geomCockpit.vertices[6].z += 20;
    // geomCockpit.vertices[7].y += 30;
    // geomCockpit.vertices[7].z -= 20;

    var cockpit = new THREE.Mesh(geomCockpit, matCockpit);
    cockpit.castShadow = true;
    cockpit.receiveShadow = true;
    this.mesh.add(cockpit);

    // Engine

    var geomEngine = new THREE.BoxGeometry(20, 50, 50, 1, 1, 1);
    var matEngine = new THREE.MeshPhongMaterial({
      color: colors.white,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var engine = new THREE.Mesh(geomEngine, matEngine);
    engine.position.x = 50;
    engine.castShadow = true;
    engine.receiveShadow = true;
    this.mesh.add(engine);

    // Tail Plane

    var geomTailPlane = new THREE.BoxGeometry(15, 20, 5, 1, 1, 1);
    var matTailPlane = new THREE.MeshPhongMaterial({
      color: colors.red,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var tailPlane = new THREE.Mesh(geomTailPlane, matTailPlane);
    tailPlane.position.set(-40, 20, 0);
    tailPlane.castShadow = true;
    tailPlane.receiveShadow = true;
    this.mesh.add(tailPlane);

    // Wings

    var geomSideWing = new THREE.BoxGeometry(30, 5, 120, 1, 1, 1);
    var matSideWing = new THREE.MeshPhongMaterial({
      color: colors.red,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var sideWing = new THREE.Mesh(geomSideWing, matSideWing);
    sideWing.position.set(0, 15, 0);
    sideWing.castShadow = true;
    sideWing.receiveShadow = true;
    this.mesh.add(sideWing);

    var geomWindshield = new THREE.BoxGeometry(3, 15, 20, 1, 1, 1);
    var matWindshield = new THREE.MeshPhongMaterial({
      color: colors.white,
      transparent: true,
      opacity: 0.3,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var windshield = new THREE.Mesh(geomWindshield, matWindshield);
    windshield.position.set(5, 27, 0);

    windshield.castShadow = true;
    windshield.receiveShadow = true;

    this.mesh.add(windshield);

    var geomPropeller = new THREE.BoxGeometry(20, 10, 10, 1, 1, 1);
    // geomPropeller.vertices[4].y -= 5;
    // geomPropeller.vertices[4].z += 5;
    // geomPropeller.vertices[5].y -= 5;
    // geomPropeller.vertices[5].z -= 5;
    // geomPropeller.vertices[6].y += 5;
    // geomPropeller.vertices[6].z += 5;
    // geomPropeller.vertices[7].y += 5;
    // geomPropeller.vertices[7].z -= 5;
    var matPropeller = new THREE.MeshPhongMaterial({
      color: colors.brown,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    this.propeller = new THREE.Mesh(geomPropeller, matPropeller);

    this.propeller.castShadow = true;
    this.propeller.receiveShadow = true;

    var geomBlade = new THREE.BoxGeometry(1, 80, 10, 1, 1, 1);
    var matBlade = new THREE.MeshPhongMaterial({
      color: colors.brownDark,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var blade1 = new THREE.Mesh(geomBlade, matBlade);
    blade1.position.set(8, 0, 0);

    blade1.castShadow = true;
    blade1.receiveShadow = true;

    var blade2 = blade1.clone();
    blade2.rotation.x = Math.PI / 2;

    blade2.castShadow = true;
    blade2.receiveShadow = true;

    this.propeller.add(blade1);
    this.propeller.add(blade2);
    this.propeller.position.set(60, 0, 0);
    this.mesh.add(this.propeller);

    var wheelProtecGeom = new THREE.BoxGeometry(30, 15, 10, 1, 1, 1);
    var wheelProtecMat = new THREE.MeshPhongMaterial({
      color: colors.red,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var wheelProtecR = new THREE.Mesh(wheelProtecGeom, wheelProtecMat);
    wheelProtecR.position.set(25, -20, 25);
    this.mesh.add(wheelProtecR);

    var wheelTireGeom = new THREE.BoxGeometry(24, 24, 4);
    var wheelTireMat = new THREE.MeshPhongMaterial({
      color: colors.brownDark,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var wheelTireR = new THREE.Mesh(wheelTireGeom, wheelTireMat);
    wheelTireR.position.set(25, -28, 25);

    var wheelAxisGeom = new THREE.BoxGeometry(10, 10, 6);
    var wheelAxisMat = new THREE.MeshPhongMaterial({
      color: colors.brown,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var wheelAxis = new THREE.Mesh(wheelAxisGeom, wheelAxisMat);
    wheelTireR.add(wheelAxis);

    this.mesh.add(wheelTireR);

    var wheelProtecL = wheelProtecR.clone();
    wheelProtecL.position.z = -wheelProtecR.position.z;
    this.mesh.add(wheelProtecL);

    var wheelTireL = wheelTireR.clone();
    wheelTireL.position.z = -wheelTireR.position.z;
    this.mesh.add(wheelTireL);

    var wheelTireB = wheelTireR.clone();
    wheelTireB.scale.set(0.5, 0.5, 0.5);
    wheelTireB.position.set(-35, -5, 0);
    this.mesh.add(wheelTireB);

    var suspensionGeom = new THREE.BoxGeometry(4, 20, 4);
    suspensionGeom.applyMatrix4(new THREE.Matrix4().makeTranslation(0, 10, 0));
    var suspensionMat = new THREE.MeshPhongMaterial({
      color: colors.red,
      flatShading: true,
      // shading: THREE.FlatShading,
    });
    var suspension = new THREE.Mesh(suspensionGeom, suspensionMat);
    suspension.position.set(-35, -5, 0);
    suspension.rotation.z = -0.3;
    this.mesh.add(suspension);

    this.pilot = new Pilot();
    this.pilot.mesh.position.set(-10, 27, 0);
    this.mesh.add(this.pilot.mesh);

    this.mesh.castShadow = true;
    this.mesh.receiveShadow = true;

    this.mesh.scale.set(0.25, 0.25, 0.25);
    this.mesh.position.y = 100;
  }

  update(yMousePosition, screenHeight) {
    this.propeller.rotation.x += 0.04;

    const minPosY = -1;
    const maxPosY = 200;
    const mouseMin = 0;
    const mouseMax = screenHeight;

    // let normalizedPosY = normalize(
    //   yMousePosition,
    //   mouseMin,
    //   mouseMax,
    //   maxPosY,
    //   minPosY
    // );

    var targetY = normalize(yMousePosition, -0.75, .75, 0, 175);

    // this.mesh.position.y = normalizedPosY;
    this.mesh.position.y += (targetY - this.mesh.position.y) * 0.1;
    this.mesh.rotation.z = (targetY - this.mesh.position.y) * 0.0128;
    this.mesh.rotation.x = (this.mesh.position.y-targetY)*0.0064

  }
}
