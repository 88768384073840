import React from "react";
import styled from "styled-components";

import { UI_COLORS } from "../../../../config/consts";

const SymbolPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 6rem;
`;

const StyledSymbolPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 3px solid ${UI_COLORS.black};
  border-radius: 12px;
  width: 100%;
  flex: 1;
  background-color: ${(props) => props.color};

  @media (max-width: 500px) {
    border: 2px solid ${UI_COLORS.black};
    }

  .symbol-price-title {
    background: linear-gradient(
      to right,
      ${(props) => props.color} 0%,
      ${(props) => props.color} calc(100% - 5px),
      ${UI_COLORS.black} calc(100% - 5px),
      ${UI_COLORS.black} 100%
    );

    /* border-radius: 12px; */
    border-radius: 10px 0 0 10px;
    flex: 1;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 8px ${UI_COLORS.black};

    @media (max-width: 500px) {
      min-height: 30px;
      box-shadow: 0 6px ${UI_COLORS.black};
    }

    p {
      font-family: "dokdo", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: ${UI_COLORS.white};
      font-size: 2.8rem;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 3rem;
      }

      @media (max-width: 500px) {
        font-size: 3.5rem;
      }
    }
  }

  .symbol-price-value {
    background-color: ${UI_COLORS.white};
    border-radius: 0 10px 10px 0;
    flex: 3;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 8px ${UI_COLORS.black};

    @media (max-width: 500px) {
      flex: 2;
      min-height: 30px;
      box-shadow: 0 6px ${UI_COLORS.black};
    }

    p {
      font-family: "dokdo", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: ${UI_COLORS.black};
      font-size: 2.8rem;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 3rem;
      }

      @media (max-width: 500px) {
        font-size: 3.5rem;
      }
    }
  }
`;

const SymbolPriceItem = ({ title, value, color }) => {
  return (
    <StyledSymbolPrice color={color}>
      <div className="symbol-price-title">
        <p>{title}</p>
      </div>
      <div className="symbol-price-value">
        <p>{value}</p>
      </div>
    </StyledSymbolPrice>
  );
};

const SymbolPrice = ({tokenPrice}) => {
  return (
    <SymbolPriceWrapper>
      <SymbolPriceItem
        title={"SYMBOL"}
        value={"ROCKTMAN"}
        color={UI_COLORS.red}
      ></SymbolPriceItem>

      <SymbolPriceItem
        title={"PRICE"}
        value={tokenPrice}
        color={UI_COLORS.blue}
      ></SymbolPriceItem>
    </SymbolPriceWrapper>
  );
};

export default SymbolPrice;
