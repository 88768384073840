import React from "react";
import styled from "styled-components";
import { UI_COLORS } from "../../../../../config/consts";

const StyledTokenomicCard = styled.div`
  position: relative;
  border: 3px solid ${UI_COLORS.black};
  border-radius: 12px;
  width: 100%;
  height: 62rem;
  /* height: auto; */
  /* max-height:500px; */
  background-color: ${UI_COLORS.white};
  overflow: hidden;
  display: flex;

  flex-direction: column;
  /* justify-content: space-between; */
  /* justify-content: flex-end; */
  gap: 2rem;
  padding: 3rem;
  box-shadow: 0 8px ${UI_COLORS.black};

  @media (max-width: 500px) {
    border: 2px solid ${UI_COLORS.black};
    box-shadow: 0 6px ${UI_COLORS.black};
  }

  .tokenomic-card-img-wrapper {
    flex: 6;
    border-radius: 12px;
    border: 3px solid ${UI_COLORS.black};
    overflow: hidden;
    background-color: ${UI_COLORS.red};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }

  .tokenomic-card-text-wrapper {
    /* margin-top:1rem; */
    flex: 1;
    p {
      font-family: "dokdo", sans-serif;
      font-weight: 400;
      font-style: normal;
      line-height: 0.8;
    }

    .tokenomic-card-text-title {
      opacity: 0.95;
      font-size: 3.6rem;
    }

    .tokenomic-card-text-value {
      font-size: 7rem;
    }
  }
`;

export default function TokenomicsCard({ img, title, value }) {
  return (
    <StyledTokenomicCard>
      <div className="tokenomic-card-img-wrapper">
        <img src={img}></img>
      </div>
      <div className="tokenomic-card-text-wrapper">
        <p className="tokenomic-card-text-title">{title}</p>
        <p className="tokenomic-card-text-value">{value}</p>
      </div>
    </StyledTokenomicCard>
  );
}
