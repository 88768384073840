import React from "react";
import styled from "styled-components";
import { UI_COLORS } from "../../../../config/consts";

const MenuWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border: 0;
  width: 50%;

  @media (max-width: 768px) {
    width: 55%;
  }
`;

const StyledLink = styled.a`
  border: none;
  outline: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  pointer-events: auto;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  span {
    /* padding-top: 0.25rem; */
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* font: inherit; */
    vertical-align: baseline;
    text-transform: uppercase;
    color: ${UI_COLORS.cream};

    font-family: "dokdo", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
    text-shadow: 0 0.4rem ${UI_COLORS.black};

    @media (max-width: 768px) {
      font-size: 4rem;
    }

    @media (max-width: 400px) {
      font-size: 4.5rem;
    }
  }
`;

export default function Menu() {
  return (
    <MenuWrapper>
      <StyledLink href="#about">
        <span>About</span>
      </StyledLink>

      <StyledLink href="#tokenomics">
        <span>Tokenomics</span>
      </StyledLink>

      <StyledLink href="#community">
        <span>Community</span>
      </StyledLink>
    </MenuWrapper>
  );
}
