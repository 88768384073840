import React from "react";
import styled from "styled-components";

import SectionWrapper from "../../components/SectionWrapper";

import AboutCard from "../../components/AboutCard";

export default function AboutSection() {
  return (
    <SectionWrapper id="about" title="about">
      <AboutCard />
    </SectionWrapper>
  );
}
