import React from "react";

import SectionWrapper from "../../components/SectionWrapper";
import SymbolPrice from "./SymbolPrice";
import ContractCard from "./ContractCard";

import TokenomicsCards from "./TokenomicsCards";
import Buy from "./Buy";

export default function Tokenomics({ tokenPrice }) {
  return (
    <SectionWrapper id="tokenomics" title="tokenomics">
      <SymbolPrice tokenPrice={tokenPrice} />
      <ContractCard />
      <TokenomicsCards />
      <Buy />
    </SectionWrapper>
  );
}
