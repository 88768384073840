import React from "react";
import styled from "styled-components";

import { UI_COLORS } from "../../../../config/consts";

const greyBlue = "#e5e8fc";
const darkBlue = "#3f568c";
const darkRed = "#df5863";
const middleRed = "#e04451";

const StyledPushButton = styled.a`
  position: relative;
  width: 7.6rem;
  height: 7.6rem;
  background-color: #bbb;
  border-radius: 50%;
  overflow: hidden;

  &:hover {
    p {
      cursor: pointer;
      font-size: 2.2rem;
      transition: all 0.008s ease;
    }
  }

  &:active {
    p {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 500px) {
    width: 9rem;
    height: 9rem;
  }

  background: radial-gradient(
    circle,
    ${middleRed} 0%,
    ${middleRed} 60%,
    ${darkRed} 61%,
    ${darkRed} 62%,
    ${darkBlue} 63%,
    ${darkBlue} 65%,
    #fff 67%,
    #fff 68%,
    ${greyBlue} 69%,
    ${greyBlue} 100%
  );

  border: 2px solid ${greyBlue};

  cursor: pointer;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: ${UI_COLORS.purple};
    opacity: 0.2;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid black;
  }

  p {
    color: ${UI_COLORS.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "dokdo", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.6rem;
    transition: all 0.05s ease;
  }
`;

export default function PushButton() {
  return (
    <StyledPushButton href="https://jup.ag/swap/SOL-4MwJtSuzKhmfTXdNtendHsrNKX2555iXwigwTK8SVyjQ" target="_blank">
      <div className="overlay"></div>
      <p>BUY</p>
    </StyledPushButton>
  );
}
