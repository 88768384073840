import React from "react";
import styled from "styled-components";

import { UI_COLORS } from "../../../../../config/consts";

const StyledBuyButton = styled.button`
  background-color: ${(props) => props.color};
  color: ${UI_COLORS.white};
  border: none;
  padding: 2.2rem 2.4rem;
  border: 3px solid ${UI_COLORS.black};
  border-radius: 12px;
  /* box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 2rem ${UI_COLORS.black};
  cursor: pointer;
  font-size: 2.6rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
  width:100%;

  @media (max-width: 500px) {
    font-size: 3rem;
    border: 2px solid ${UI_COLORS.black};
  }

  &:active {
    transform: translateY(4px);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }

  &:focus:not(:active) {
    box-shadow: 0 2rem ${UI_COLORS.black};
  }
`;

export default function BuyButton({ title, color, href }) {
  return <StyledBuyButton color={color}>{title}</StyledBuyButton>;
}
