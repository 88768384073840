export default [
  // {
  //   name: "testTexture",
  //   type: "texture",
  //   path: "models/baked.jpg",
  // },
  {
    name: "testModel",
    type: "gltfModel",
    path: "models/test.glb",
  },
];
